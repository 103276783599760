<template>
	<div class="lc-work">
		<div class="lc-work-search el-content mb12">
			<a-space>
				<a-input-group compact>
					<a-select v-model:value="search.order_status" style="width: 120px;">
						<a-select-option value="">订单状态</a-select-option>
						<a-select-option value="1">待分配</a-select-option>
						<a-select-option value="2">进行中</a-select-option>
						<a-select-option value="3">已完成</a-select-option>
					</a-select>
					<a-input style="width:260px;" v-model:value="search.order_number" placeholder="订单号" />
				</a-input-group>
				<a-input-search
					v-model:value="search.mobile"
					placeholder="手机号"
					enter-button
					@search="getLandCustomWorkList(1,info.limit)"
				/>
			</a-space>
		</div>
		<div class="el-content">
			<div class="kd-pacel">列表</div>
			<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'工单类型',dataIndex:'type',slots:{customRender:'type'}},
				{title:'操作对象',dataIndex:'land',slots:{customRender:'land'}},
				{title:'面积',dataIndex:'area',slots:{customRender:'area'}},
				{title:'订单状态',dataIndex:'order_status',slots:{customRender:'order_status'}},
				{title:'工人',dataIndex:'worker',slots:{customRender:'worker'}},
				{title:'备注',dataIndex:'remark'},
				{title:'创建时间',dataIndex:'create_time'},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]" >
				<template #type="{record}">
					<div><span class="f12">订单编号：</span>{{ record.order_number}}</div>
					<div><span class="f12">工单类型：</span>
						<span v-if="record.serverdetails">{{record.serverdetails.title}}</span>
						<span v-if="record.is_decorate > 0">装饰</span>
					</div>
					<div><span class="f12">操作详情：</span>{{ record.title}}</div>
				</template>
				<template #land="{record}">
					<div v-if="record.land"><span class="f12">名称：</span>{{ record.land.land_name}}</div>
					<div v-if="record.land_spec"><span class="f12">编号：</span>{{ record.land_spec.land_num}}</div>
					<div v-if="record.serverdetails && record.serverdetails.special_type == 7"><span class="f12">对象：</span>{{ record.mapchil.plant.name}}</div>
					<div v-if="record.serverdetails && record.serverdetails.special_type == 7"><span class="f12">重量：</span>{{ record.weight}}kg</div>
				</template>
				<template #area="{record}">
					<span  v-if="record.land_spec">{{ record.land_spec.area }}㎡</span>
				</template>
				<template #order_status="{record}">
					<a-tag color="#FF6600" v-if="record.order_status == 1">待分配</a-tag>
					<a-tag color="#33CCCC" v-if="record.order_status == 2">进行中</a-tag>
					<a-tag color="#00CC66" v-if="record.order_status == 3">已完成</a-tag>
				</template>
				<template #worker="{record}">
					<div v-if="record.worker_name"><span class="f12">姓名：</span>{{ record.worker_name }}</div>
					<div v-if="record.worker_mobile"><span class="f12">电话：</span>{{ record.worker_mobile }}</div>
				</template>
				<template #action="{record}">
					<a-button v-has="{action:'wxapp_land_custom_work_del',plat:0}" type="primary" @click="showSetWorker(record.id)">指派工单</a-button>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getLandCustomWorkList(info.page,e)}"
					@change="(e)=>{getLandCustomWorkList(e,info.limit)}"
				/>
			</div>
		</div>
		<a-modal v-model:visible="show.setworker" title="指派工单" @ok="saveSetWorker" width="500px" @cancel="show.setworker = false">
			<a-select v-model:value="setWorkerForm.user_id" placeholder="选择工人" style="width: 400px;">
				<a-select-option value="">选择工人</a-select-option>
				<a-select-option v-for="(val,ind) in worker" :key="ind" :value="val.id">{{val.name}}</a-select-option>
			</a-select>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import landCustom from '@/api/addons/landCustom.js'
import landModel from '@/api/land.js'
export default{
	setup(){
		const _d = reactive({
			info:{
				list:[],
				page:1,
				limit:10,
				count:0,
			},
			search:{
				order_number:"",
				mobile:"",
				order_status:"",
			},
			show:{setworker:false},
			setWorkerForm:{
				id:0,
				user_id:"",
			},
			worker:[],		//工人列表
		})
		getLandCustomWorkList(1,_d.info.limit)
		landModel.getInspector(1,999,{type:2},res=>_d.worker = res.list)
		function getLandCustomWorkList(page,limit){
			landCustom.getCusomLandWorkOrder(page,limit,_d.search,res=>_d.info = {limit,...res})
		}
		function showSetWorker(id){
			_d.show.setworker = true
			_d.setWorkerForm.id = id
		}

		const saveSetWorker = ()=>landCustom.setCustomWorkOrderWorker(_d.setWorkerForm.user_id,_d.setWorkerForm.id,()=>{
			_d.show.setworker = false
			getLandCustomWorkList(_d.info.page,_d.info.limit)
		})

		return{
			...toRefs(_d),
			getLandCustomWorkList,
			showSetWorker,
			saveSetWorker
		}
	}
}
</script>
<style lang="scss">
</style>
